import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomPacmanLoader } from "./PacmanLoader";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const NotesComponent = ({ candidateId, open, onClose, candidateName }) => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [isEditing, setIsEditing] = useState(null); // To track the note being edited
  const [editText, setEditText] = useState(""); // Text being edited
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { companyId, positionId } = useParams();
  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (open && candidateId) {
      getCandidateNotes(candidateId);
    }
  }, [candidateId, open]);

  const getCandidateNotes = async (candidateId) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${companyId}/candidates/${candidateId}/notes`,
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );
      if (response.data) {
        setIsLoading(false);
        setNotes(response.data);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      toast.error("Failed to fetch notes");
    }
  };

  const handleAddNote = async (e) => {
    e.preventDefault();
    if (!newNote.trim()) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${companyId}/${positionId}/candidates/${candidateId}/notes`,
        { note: newNote },
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        const today = new Date();
        const isoDate = today.toISOString();

        setNotes([
          ...notes,
          {
            text: newNote,
            createdAt: isoDate,
            recruiterName: userData?.fullname || "",
            id: response.data?.id || 0,
          },
        ]);
        setNewNote("");
      }
    } catch (error) {
      console.error("Error adding note:", error);
    }
  };

  const handleNoteDate = (date) => {
    const isoDate = date;
    const dateObj = new Date(isoDate);
    return dateObj.toLocaleDateString("en-GB").replace(/\//g, ".");
  };

  const handleEditNote = (index) => {
    setIsEditing(index);
    setEditText(notes[index]?.text || "");
  };

  const handleSaveEdit = async (index) => {
    try {
      const updatedNote = { ...notes[index], text: editText };
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${companyId}/${positionId}/candidates/${candidateId}/${notes[index].id}/notes`,
        { note: updatedNote.text },
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );

      if (response.status === 200) {
        const updatedNotes = [...notes];
        updatedNotes[index] = updatedNote;
        setNotes(updatedNotes);
        setIsEditing(null);
        toast.success("Note updated successfully!");
      }
    } catch (error) {
      console.error("Error updating note:", error);
      toast.error("Failed to update note");
    }
  };

  const handleDeleteNote = async (index) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${companyId}/${positionId}/candidates/${candidateId}/${notes[index].id}/notes`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );

      if (response.status === 200) {
        const updatedNotes = [...notes];
        updatedNotes.splice(index, 1);
        setNotes(updatedNotes);
        toast.success("Note deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting note:", error);
      toast.error("Failed to delete note");
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(null);
    setEditText("");
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      className="notesDialog"
    >
      <DialogTitle>
        Notes{" "}
        {candidateName && (
          <div
            className="notesTitleName"
            style={{ fontSize: "10px", color: "#278aac" }}
          >
            {candidateName}
          </div>
        )}
      </DialogTitle>

      <DialogContent dividers>
        {isLoading && <CustomPacmanLoader />}
        {!isLoading && (
          <>
            <form onSubmit={handleAddNote} style={{ marginTop: "20px" }}>
              <TextField
                // disabled={isEditing !== null}
                fullWidth
                variant="outlined"
                placeholder="Write a new note..."
                value={newNote}
                rows={3}
                multiline
                onChange={(e) => setNewNote(e.target.value)}
              />
              {/* <p
                style={{
                  fontSize: "0.8em",
                  color: "gray",
                  marginTop: "0px",
                  textAlign: "right",
                }}
              >
                {newNote?.length || 0}/1000
              </p> */}
            </form>
            {notes.length > 0 && (
              <List>
                {notes.map((note, index) => (
                  <ListItem key={index} sx={{ borderBottom: "1px solid #ccc" }}>
                    {isEditing === index ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={editText}
                        onChange={(e) => setEditText(e.target.value)}
                        multiline
                        // rows={2}
                      />
                    ) : (
                      <ListItemText
                        primary={note?.text}
                        secondary={
                          handleNoteDate(note?.createdAt) +
                          " " +
                          note?.recruiterName
                        }
                        secondaryTypographyProps={{
                          color: "gray",
                          fontSize: "11px",
                        }}
                      />
                    )}
                    {isEditing === index ? (
                      <div className="editModeNote">
                        <Tooltip title="Save Note" placement="bottom">
                          <CheckCircleOutlineIcon
                            className="saveEditNote"
                            onClick={() => handleSaveEdit(index)}
                          />
                        </Tooltip>
                        <Tooltip title="Cancel Edit" placement="bottom">
                          <HighlightOffIcon
                            onClick={handleCancelEdit}
                            className="cancelEditNote"
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="editDeleteNote">
                        <Tooltip title="Edit Note" placement="bottom">
                          <EditIcon
                            onClick={() => handleEditNote(index)}
                            className="editNoteIcon"
                          />
                        </Tooltip>
                        <Tooltip
                          title="Delete Note"
                          placement="bottom"
                          className="deleteNoteIcon"
                        >
                          <DeleteIcon onClick={() => handleDeleteNote(index)} />
                        </Tooltip>
                      </div>
                    )}
                  </ListItem>
                ))}
              </List>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-around" }}>
        <Button
          disabled={newNote.length === 0}
          type="submit"
          variant="text"
          onClick={handleAddNote}
          style={{
            color: newNote.length === 0 ? "grey" : "#E9E9F1",
            textTransform: "none",
          }}
        >
          Add Note
        </Button>
        <Button
          onClick={() => {
            setNewNote("");
            setIsEditing(null);
            onClose();
          }}
          color="error"
          style={{ textTransform: "none" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NotesComponent.propTypes = {
  candidateId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  candidateName: PropTypes.string,
};

NotesComponent.defaultProps = {
  candidateName: "",
};

export default NotesComponent;
