import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { Tooltip, TextField } from "@mui/material";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import AttentionDialog from "../../commonComponents/AttentionDialog.jsx";
import { CustomPacmanLoader } from "../../commonComponents/PacmanLoader.jsx";
import { toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import CSS
import PersonAddIcon from "@mui/icons-material/PersonAddOutlined";
import AddEmployeeDialog from "./AddEmployee.jsx";
import noCompaniesImg from "../../../assets/imgs/noCompaniesNoText.png";

const Employees = () => {
  const [employees, setEmployees] = useState([]);
  const [noEmployees, setNoEmployees] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { companyId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useAuth0();
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddEmployeeDialog, setOpenAddEmployeeDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isEmployeeAdded, setIsEmployeeAdded] = useState(false);
  const [ownerEmail, setOwnerEmail] = useState("");

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "userData") {
        const userData = event.newValue ? JSON.parse(event.newValue) : null;

        if (userData?.fullName && employees.length > 0) {
          setEmployees((prev) => {
            const updatedEmployees = [...prev];
            const ownerIndex = updatedEmployees.findIndex(
              (employee) => employee.role === "Owner"
            );

            if (ownerIndex !== -1) {
              updatedEmployees[ownerIndex] = {
                ...updatedEmployees[ownerIndex],
                fullName: userData.fullName,
              };
            }

            return updatedEmployees;
          });
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    fetchEmployees();
  }, [companyId, page, getAccessTokenSilently]);

  useEffect(() => {
    if (isEmployeeAdded) {
      fetchEmployees();
    }
  }, [isEmployeeAdded]);

  const fetchEmployees = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/Employees/${companyId}?Page=${page}&Amount=100000`,
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );

      if (response && response.status === 200 && response.data.length > 0) {
        setLoading(false);
        setEmployees(response.data);
        setOwnerEmail(
          response.data.find((employee) => employee.role === "Owner").email
        );
      } else {
        setNoEmployees(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveEmployee = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/Employees/${companyId}/${selectedEmployee.email}`,
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );

      if (response && response.status === 200) {
        setEmployees((prev) =>
          prev.filter((employee) => employee.email !== selectedEmployee.email)
        );
        setOpenDialog(false);
        setSelectedEmployee(null);
        toast.success(
          `${selectedEmployee.fullName} has been removed successfully!`
        ); // Show success message
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to remove employee."); // Show error message
    }
  };

  const openRemoveDialog = (employee) => {
    setSelectedEmployee(employee);
    setOpenDialog(true);
  };

  const filteredEmployees = employees.filter((employee) =>
    employee.fullName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="employeesWrapper">
      <div className="employeeSearchWrapper">
        <TextField
          label="Search Employees"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Tooltip title="Add employee">
          <PersonAddIcon
            style={{ width: "40px", height: "40px", cursor: "pointer" }}
            onClick={() => setOpenAddEmployeeDialog(true)}
          />
        </Tooltip>
      </div>
      <div className="employeesCards">
        {loading ? (
          <CustomPacmanLoader />
        ) : filteredEmployees.length > 0 ? (
          filteredEmployees.map((employee) => (
            <div className="employeeCard" key={employee.email}>
              <div className="employeeName">{employee.fullName}</div>
              <div className="employeeEmail">
                <MailOutlineIcon />
                {employee.email}
              </div>
              <div className="employeeRole">
                <AssignmentIndIcon />
                {employee.role}
              </div>
              {employee.role !== "Owner" && ownerEmail === user.email && (
                <Tooltip title="Delete Employee and remove access">
                  <div className="deleteEmployee">
                    <PersonRemoveIcon
                      onClick={() => openRemoveDialog(employee)}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          ))
        ) : (
          <div className="noEmployeesResult">
            <span>No search result</span>
            <img src={noCompaniesImg} alt="No employees found" />
          </div>
        )}
      </div>
      <AttentionDialog
        open={openDialog}
        handleOK={handleRemoveEmployee}
        handleCancel={() => setOpenDialog(false)}
        title="Remove Employee"
        message={`Are you sure you want to remove ${selectedEmployee?.fullName} from your employees list?`}
        okBtnText="Remove"
        cancelBtnText="Cancel"
      />
      <AddEmployeeDialog
        isOpen={openAddEmployeeDialog}
        setOpenAddEmployeeDialog={() => setOpenAddEmployeeDialog(false)}
        setIsEmployeeAdded={setIsEmployeeAdded}
      />
    </div>
  );
};

export default Employees;
