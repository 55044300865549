import React, { useEffect, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import ClearIcon from "@mui/icons-material/Clear";
import { BeatLoader } from "react-spinners";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Tooltip,
  Card,
  CardContent,
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  CardActionArea,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import "./pendingCandidates.scss";
import EditIcon from "@mui/icons-material/Edit";
import AttentionDialog from "../../commonComponents/AttentionDialog";
import { isNotNull } from "../../../Utils/Utils";
import InvitationTextDialog from "../invitation/InvitationTextDialog";
import { MEDIA_QUERIES } from "../../../Utils/constants";

const PendingCandidates = ({
  positionInfo,
  getPosition,
  cvsLoading,
  temporaryCandidates,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { companyId, positionId } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [editedCandidate, setEditedCandidate] = useState({
    id: "",
    fullName: "",
    userEmail: "",
  });
  const [isOpenInviteDialog, setIsOpenInviteDialog] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [pendingCandidates, setPendingCandidates] = useState([]);
  const [isOpenEditDialog, setOpenEditDialog] = useState(false);
  const [inviteEmailContent, setInviteEmailContent] = useState("");
  const isMobile = useMediaQuery(MEDIA_QUERIES.PHONE);

  useEffect(() => {
    if (isNotNull(positionInfo) && isNotNull(positionInfo.inviteEmailContent)) {
      setInviteEmailContent(positionInfo.inviteEmailContent);
    }
  }, [positionInfo]);

  useEffect(() => {
    if (isNotNull(temporaryCandidates)) {
      setPendingCandidates(temporaryCandidates);
    }
  }, [temporaryCandidates]);

  const handleSendInvite = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${companyId}/${selectedCandidate.id}/approveextractedcandidate`,
        {},
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );
      if (response.status === 200) {
        toast.success("Interview invitation sent successfully");
        await getPosition(true);
        setIsOpenInviteDialog(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error sending interview invitation");
      setIsOpenInviteDialog(false);
    }
    return;
  };

  const handleDialogOpen = (candidate) => {
    setEditedCandidate(candidate);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setEditedCandidate({ id: "", fullName: "", userEmail: "" });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCandidate((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await handleCandidateEditing(editedCandidate.id, {
        fullName: editedCandidate.fullName,
        userEmail: editedCandidate.userEmail,
      });
      handleDialogClose();
    } catch (error) {
      console.error("Error updating candidate", error);
    }
  };

  const handleCandidateEditing = async (editedCandidateId, value) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${companyId}/${editedCandidateId}/updateExtractedCandidate`,
        {
          fullName: value.fullName,
          email: value.userEmail,
        },
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );

      if (response.status === 200) {
        toast.success("Candidate updated successfully");
        const updatedCandidatesList = pendingCandidates.map((c) => {
          if (c.id === editedCandidateId) {
            return {
              ...c,
              fullName: value.fullName,
              userEmail: value.userEmail,
            };
          }
          return c;
        });
        setPendingCandidates(updatedCandidatesList);
        // await getPosition(); // Refresh position data
      }
    } catch (error) {
      console.error(error);
      toast.error("Error while updating candidate");
    }
  };

  const removeErrorCV = async (cv) => {
    try {
      const cvPath = encodeURIComponent(cv.filePath);
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/positions/${companyId}/${positionId}/${cvPath}/updateCvInProcessVisibility`,
        {},
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );
      if (response.status === 200) {
        await getPosition();
      }
    } catch (error) {
      console.error(error);
      toast.error("Error removing CV");
    }
  };

  const handleDeletePendingCandidate = async (candidateId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${companyId}/${candidateId}/deleteExtractedCandidate`,
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );
      if (response.status === 200) {
        toast.success("Candidate removed successfully");
        setPendingCandidates((prev) =>
          prev.filter((c) => c.id !== candidateId)
        );
        // await getPosition();
      }
    } catch (error) {
      console.error(error);
      toast.error("Error removing candidate");
    }
  };

  const handleOpenCv = (cvPath) => {
    if (cvPath) {
      window.open(cvPath, "_blank"); // Open the PDF in a new tab
    } else {
      console.error("CV path is not available.");
    }
  };

  return (
    <div className="cvInProcess">
      <Accordion className="accordionCvInProcess">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {cvsLoading ? (
            <BeatLoader className="beatLoaderCvs" color="#278aac" />
          ) : (
            <Typography className="cvInProcessHeader">
              Pending CVs:
              {positionInfo?.cVsToProcess.length + pendingCandidates?.length}
              {!cvsLoading && !isMobile && (
                <div className="editInviteEmail" style={{ height: "24px" }}>
                  <Tooltip title="Edit email invitation">
                    <EditIcon
                      className="editInviteEmailBtn"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenEditDialog(true);
                      }}
                    />
                  </Tooltip>
                </div>
              )}
              <Tooltip title="Refresh">
                <RefreshIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    getPosition(true);
                  }}
                />
              </Tooltip>
            </Typography>
          )}
        </AccordionSummary>
        {!cvsLoading && isMobile && (
          <div className="editInviteEmail">
            <Button
              className="editInviteEmailBtn"
              variant="text"
              onClick={() => setOpenEditDialog(true)}
            >
              Edit invitation <EditIcon />
            </Button>
          </div>
        )}
        {!cvsLoading && (
          <AccordionDetails>
            {positionInfo?.cVsToProcess.length > 0 && (
              <Box className="inProcessList">
                <div className="textCvInProcess">In process:</div>
                {positionInfo?.cVsToProcess
                  ?.filter((cv) => cv.status === "NotStarted")
                  ?.map((cv) => (
                    <div key={cv.submittedFileName}>
                      <Typography>{cv.submittedFileName}</Typography>
                    </div>
                  ))}
              </Box>
            )}
            {positionInfo?.cVsToProcess?.filter(
              (cv) => cv.status === "SomeErrorOccurred"
            ).length > 0 && (
              <Box className="errorList">
                <div className="textCvInProcess">Error processing CVs: </div>
                {positionInfo?.cVsToProcess
                  ?.filter((cv) => cv.status === "SomeErrorOccurred")
                  .map((cv) => (
                    <div key={cv.submittedFileName} className="cvList">
                      <Typography>
                        {cv.submittedFileName}
                        <Tooltip title="Remove">
                          <ClearIcon onClick={() => removeErrorCV(cv)} />
                        </Tooltip>
                      </Typography>
                    </div>
                  ))}
              </Box>
            )}
            {isNotNull(pendingCandidates) && pendingCandidates.length > 0 && (
              <div className="temporaryCandidates">
                <div className="textCvInProcess">Pending candidates: </div>
                {pendingCandidates.map((candidate) => (
                  <Card className="temporaryCandidatesCard" key={candidate.id}>
                    <CardContent className="temporaryCandidatesCardContent">
                      <div className="tempInfoText tempName">
                        <div className="tempCandidateName">
                          {candidate.fullName}
                        </div>
                        <Tooltip title="Edit pending candidate">
                          <EditIcon
                            className="editIcon"
                            onClick={() => handleDialogOpen(candidate)}
                          />
                        </Tooltip>
                      </div>
                      <div className="tempInfoText">{candidate.userEmail}</div>
                      <div className="tempInfoText">
                        {new Date(candidate.createdAt).toDateString()}
                      </div>
                      <div className="viewPendingCV">
                        <Button
                          onClick={() =>
                            handleOpenCv(candidate.cvSkills?.filePath)
                          }
                          variant="text"
                          color="primary"
                          style={{
                            padding: "0px",
                            height: "25px",
                            textTransform: "none",
                            textDecoration: "underline",
                            color: "#1976D2",
                          }}
                          disabled={!candidate.cvSkills.filePath}
                        >
                          View CV
                        </Button>
                      </div>
                    </CardContent>
                    <CardActions className="tempActions">
                      <Button
                        className="tempBtnInvite"
                        onClick={() => {
                          setSelectedCandidate(candidate);
                          setIsOpenInviteDialog(true);
                        }}
                        disabled={!candidate.userEmail}
                      >
                        Invite
                      </Button>
                      <Button
                        className="tempBtnDelete"
                        color="error"
                        onClick={() => {
                          handleDeletePendingCandidate(candidate.id);
                        }}
                      >
                        Remove
                      </Button>
                    </CardActions>
                  </Card>
                ))}
              </div>
            )}
          </AccordionDetails>
        )}
      </Accordion>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        className="editCandidateDialog"
      >
        <DialogTitle>Edit Candidate</DialogTitle>
        <DialogContent dividers>
          <TextField
            margin="dense"
            label="Full Name"
            type="text"
            fullWidth
            name="fullName"
            value={editedCandidate.fullName}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            name="userEmail"
            value={editedCandidate.userEmail}
            onChange={handleInputChange}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSave} className="tempBtnInvite">
            Save
          </Button>
          <Button
            onClick={handleDialogClose}
            color="error"
            className="tempBtnDelete"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <AttentionDialog
        open={isOpenInviteDialog}
        handleOK={handleSendInvite}
        handleCancel={() => {
          setSelectedCandidate(null);
          setIsOpenInviteDialog(false);
        }}
        okBtnText={"Send"}
        cancelBtnText={"Cancel"}
        title={"Send Interview Invitation"}
        message={`Are you sure you want to send an interview invitation for ${positionInfo?.title} position to ${selectedCandidate?.fullName} (${selectedCandidate?.userEmail})?`}
      />
      {isNotNull(inviteEmailContent) && (
        <InvitationTextDialog
          open={isOpenEditDialog}
          handleCancel={() => {
            setOpenEditDialog(false);
          }}
          okBtnText={"Send"}
          cancelBtnText={"Cancel"}
          inviteEmailContent={inviteEmailContent}
        />
      )}
    </div>
  );
};

export default PendingCandidates;
