import React, { useState, useEffect, useRef } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./addCandidates.scss";
import axios from "axios";
import { Button, Tooltip } from "@mui/material";
import CandidatesList from "./CandidatesList";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import {
  CANDIDATE_STATUS,
  DECISION_ON_CANDIDATE,
} from "../../../Utils/constants";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import PendingCandidates from "./PendingCandidates";
import TableChartIcon from "@mui/icons-material/TableChart";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import noCandidatesImg from "../../../assets/imgs/noCandidatesFound.png";
import { CustomPacmanLoader } from "../../commonComponents/PacmanLoader";

const AddCandidates = () => {
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [candidatesList, setCandidatesList] = useState([]);
  const { companyId, positionId } = useParams();
  const [isTableView, setIsTableView] = useState(false);

  const [positionInfo, setPositionInfo] = useState(null);
  const [cvsLoading, setCvsLoading] = useState(false);
  const [temporaryCandidates, setTemporaryCandidates] = useState([]);
  const intervalRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getPosition();
    getCandidates();
  }, []);

  const getPosition = async (isInvite) => {
    setCvsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/positions/${companyId}/${positionId}`,
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );
      if (response.status === 200) {
        setPositionInfo(response.data);
        setCvsLoading(false);
        if (isInvite) {
          getCandidates();
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Error getting position info");
      setCvsLoading(false);
    }
  };

  //UPLOAD FILES
  useEffect(() => {
    if (selectedFiles.length > 0) {
      const uploadFiles = async () => {
        try {
          const formData = new FormData();

          selectedFiles.map(async (file) => {
            formData.append("file", file);
          });

          const response = await axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/api/storage/simulated-storage/${companyId}/${positionId}/uploadcvs?`,
            formData,
            {
              headers: {
                Authorization: "Bearer " + (await getAccessTokenSilently()),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          await getPosition();
          console.log("Uploaded file URLs:", response);
        } catch (error) {
          console.error("Error uploading files:", error);
        }
      };

      uploadFiles();
    }
  }, [selectedFiles]);

  //GET CANDIDATES LIST
  useEffect(() => {
    // Function to check if there are any 'NotStarted' CVs
    const hasNotStartedCVs = () => {
      return positionInfo?.cVsToProcess?.some(
        (cv) => cv.status === "NotStarted"
      );
    };

    if (hasNotStartedCVs()) {
      // If there are 'NotStarted' CVs, set up the interval
      if (!intervalRef.current) {
        intervalRef.current = setInterval(() => {
          getPosition();
        }, 30000); // 30 seconds
      }
    } else {
      // If there are no 'NotStarted' CVs, clear the interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }

    // getCandidates();
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [positionInfo]);

  const getCandidates = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${companyId}/${positionId}/getcandidates`,
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );
      if (response.status === 200) {
        const candidates = response.data.map((candidate) => ({
          ...candidate,
          candidateStatus: CANDIDATE_STATUS[candidate.candidateStatus],
          decision: DECISION_ON_CANDIDATE[candidate.decision],
        }));
        const filterNotTemporary = candidates.filter(
          (candidate) => candidate.isTemporary === false
        );
        setTemporaryCandidates(
          candidates?.filter((candidate) => candidate.isTemporary === true)
        );
        setCandidatesList(filterNotTemporary);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error getting candidates");
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...files]); // Convert FileList to an array
    event.target.value = null;
  };
  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  const columnsData = [
    {
      accessorKey: "fullName",
      header: "Name",
      enableClickToCopy: true,
    },
    {
      accessorKey: "userEmail",
      header: "Email",
      enableClickToCopy: true,
    },
    {
      accessorKey: "candidateStatus",
      header: "Status",
    },
    {
      accessorKey: "score",
      header: "Score",
    },
    {
      accessorKey: "decision",
      header: "Decision",
    },

    {
      accessorKey: "details",
      header: "Details",
    },
    {
      accessorKey: "notes",
      header: "Notes",
    },
  ];

  return (
    <div className="addCandidatesWrapper">
      <div className="addCandidatesHeader">
        <div
          className="backToPositions"
          onClick={() => navigate(`/manage-company/${companyId}/positionsList`)}
        >
          <ArrowBackIosIcon /> <span>Back to positions</span>
        </div>
        <div className="headerText">{positionInfo?.title}</div>
      </div>
      <div className="btnAddCandidates">
        <Button onClick={handleButtonClick} variant="outlined">
          Add Candidates <GroupAddOutlinedIcon />
          <input
            type="file"
            id="fileInput"
            multiple
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </Button>
      </div>
      <PendingCandidates
        positionInfo={positionInfo}
        getPosition={getPosition}
        cvsLoading={cvsLoading}
        temporaryCandidates={temporaryCandidates}
      />
      {candidatesList?.length > 0 ? (
        <div className="candidatesTableWrapper">
          <div className="switchListView">
            <Tooltip title="Table View">
              <TableChartIcon
                className={isTableView ? "active" : ""}
                onClick={() => setIsTableView(true)}
              />
            </Tooltip>
            <Tooltip title="Card View">
              <ViewModuleIcon
                className={isTableView ? "" : "active"}
                onClick={() => setIsTableView(false)}
              />
            </Tooltip>
          </div>
          <CandidatesList
            candidates={candidatesList}
            columnsData={columnsData}
            loading={loading}
            isTableView={isTableView}
          />
        </div>
      ) : (
        <div className="noCandidatesFound">
          <img src={noCandidatesImg} alt="No Candidates Found" />
        </div>
      )}
    </div>
  );
};

export default AddCandidates;
