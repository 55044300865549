import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Slide,
} from "@mui/material";
import EmailTemplate from "./EmailTemplate";
import { isNotNull } from "../../../Utils/Utils";
import InfoSection from "../../commonComponents/InfoSection";
import { EDIT_INVITATION_EXPLANATION } from "../../../Utils/texts";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InvitationTextDialog = ({ open, handleCancel, inviteEmailContent }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [invitationText, setInvitationText] = useState({
    emailSubject: "",
    titleText: "",
    greetingText: "",
    bodyText: "",
    inviteValidityDays: "",
    inviteButtonText: "",
  });
  const [previewHtml, setPreviewHtml] = useState(null);
  const replacementConsts = {
    titleText: "{{title}}",
    bodyText: "{{bodyText}}",
    greetingText: "{{candidateGreeting}}",
    inviteButtonText: "{{inviteButtonText}}",
  };
  const { companyId, positionId } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isNotNull(inviteEmailContent)) {
      setInvitationText({
        bodyText: inviteEmailContent.bodyText || "",
        emailSubject: stripHtmlTags(inviteEmailContent.emailSubject || ""),
        greetingText: stripHtmlTags(inviteEmailContent.greetingText || ""),
        inviteButtonText: stripHtmlTags(
          inviteEmailContent.inviteButtonText || ""
        ),
        titleText: stripHtmlTags(inviteEmailContent.titleText || ""),
        inviteValidityDays: inviteEmailContent.inviteValidityDays || "",
      });

      if (isNotNull(inviteEmailContent.emailHtml)) {
        handleInviteHTMLChange(inviteEmailContent);
      }
    }
  }, [inviteEmailContent]);

  const stripHtmlTags = (html) => {
    if (typeof html !== "string") return html;
    return html?.replace(/<\/?[^>]+(>|$)/g, ""); // Removes all HTML tags
  };

  const handleInviteHTMLChange = (replaceTo) => {
    if (isNotNull(replaceTo)) {
      let html = inviteEmailContent.emailHtml;
      for (const key in replacementConsts) {
        html = html.replaceAll(replacementConsts[key], replaceTo[key]);
      }
      setPreviewHtml(html);
    }
  };

  // const handleValidityDate = () => {
  //   if (isNotNull(invitationText.inviteValidityDays)) {
  //     let html = previewHtml;
  //     const daysToAdd = Number(invitationText.inviteValidityDays);
  //     const today = new Date();
  //     today.setDate(today.getDate() + daysToAdd);
  //     const formattedDate = today.toLocaleDateString("en-GB", {
  //       day: "2-digit",
  //       month: "2-digit",
  //       year: "numeric",
  //     });

  //     html = html?.replaceAll(
  //       replacementConsts.inviteValidityDays,
  //       formattedDate
  //     );
  //     setPreviewHtml(html);
  //   }
  // };

  const handleInputChange = (key, value) => {
    setInvitationText((prev) => ({
      ...prev,
      [key]: value,
    }));
    handleInviteHTMLChange({ ...invitationText, [key]: value });
  };

  const handleSave = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/Positions/${companyId}/${positionId}/updateEmailContent`,
        {
          inviteEmailContent: {
            emailSubject: invitationText?.emailSubject || "",
            titleText: invitationText?.titleText || "",
            bodyText: invitationText?.bodyText || "",
            greetingText: invitationText?.greetingText || "",
            inviteButtonText: invitationText?.inviteButtonText || "",
            inviteValidityDays: Number(invitationText?.inviteValidityDays) || 0,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );

      if (response.status === 200) {
        toast.success("Invitation email updated successfully!");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error updating invitation email");
    }
    handleCancel();
  };

  return (
    <>
      <Dialog
        className="attentionDialog"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCancel}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle textAlign="center">Edit Invitation Email</DialogTitle>
        <DialogContent style={{ padding: "20px" }} dividers>
          <DialogContentText id="alert-dialog-slide-description">
            <InfoSection
              text={EDIT_INVITATION_EXPLANATION}
              title={"Personalizing Your Invitation Text"}
            />

            <div className="previewButtonContainer">
              <Button
                onClick={() => {
                  setIsPreviewOpen(true);
                }}
                variant="text"
                disabled={!isNotNull(previewHtml)}
                className="previewButton"
              >
                Preview
              </Button>
            </div>
          </DialogContentText>

          <div style={{ marginTop: "20px" }}>
            {Object.keys(invitationText).map((key) => (
              <TextField
                type={key === "inviteValidityDays" ? "number" : "text"}
                multiline={key !== "inviteValidityDays"}
                size="small"
                key={key}
                label={key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
                value={stripHtmlTags(invitationText[key])}
                onChange={(e) => handleInputChange(key, e.target.value)}
                fullWidth
                margin="normal"
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "20px" }}>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button color="error" onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
        <EmailTemplate
          open={isPreviewOpen}
          onClose={() => setIsPreviewOpen(false)}
          htmlToRender={previewHtml?.replaceAll("\n", "<br />")}
          emailSubject={invitationText?.emailSubject}
        />
      </Dialog>
    </>
  );
};

export default InvitationTextDialog;
