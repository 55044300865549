import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "@mui/material";
import { MEDIA_QUERIES } from "../../../Utils/constants";

const validationSchema = Yup.object({
  employeeName: Yup.string().required("Employee name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  role: Yup.string().required("Role is required"),
});

const AddEmployeeDialog = ({
  isOpen,
  setOpenAddEmployeeDialog,
  setIsEmployeeAdded,
}) => {
  const [open, setOpen] = useState(false);
  const { companyId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const isTablet = useMediaQuery(MEDIA_QUERIES.TABLET_SMALL);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
    setOpenAddEmployeeDialog && setOpenAddEmployeeDialog(false);
    setIsEmployeeAdded(false);
  };

  const formik = useFormik({
    initialValues: {
      employeeName: "",
      email: "",
      role: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await handleSubmit(); // Call handleSubmit on formik submit
    },
  });

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/employees/${companyId}`,
        {
          fullName: formik.values.employeeName,
          email: formik.values.email,
          role: formik.values.role,
        },
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );
      toast.success("Employee added successfully!");
      formik.resetForm();
      setIsEmployeeAdded(true);
      setOpen(false);
      setOpenAddEmployeeDialog && setOpenAddEmployeeDialog(false); // Close the dialog on successful submission
    } catch (error) {
      console.error(error);
      toast.error("Failed to add employee.");
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="addEmployeeDialog"
        maxWidth="md"
      >
        <DialogTitle>Add Employee</DialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: isTablet ? 200 : 400,
              margin: "0 auto",
            }}
          >
            <TextField
              fullWidth
              id="employeeName"
              name="employeeName"
              label="Name"
              size="small"
              value={formik.values.employeeName}
              onChange={formik.handleChange}
              error={
                formik.touched.employeeName &&
                Boolean(formik.errors.employeeName)
              }
              helperText={
                formik.touched.employeeName && formik.errors.employeeName
              }
            />
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              type="email"
              size="small"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <FormControl
              fullWidth
              error={formik.touched.role && Boolean(formik.errors.role)}
            >
              <InputLabel id="role-label" size="small">
                Role
              </InputLabel>
              <Select
                size="small"
                labelId="role-label"
                id="role"
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
                label="Role"
                defaultValue="Admin"
              >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Employee">Employee</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={formik.handleSubmit} className="createBtn">
            Add
          </Button>
          <Button onClick={handleClose} color="error" className="cancelBtn">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddEmployeeDialog;
