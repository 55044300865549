export const POSITION_STATUS = {
  Active: "Active",
  Inactive: "Inactive",
  Deleted: "Deleted",
};

export const CANDIDATE_STATUS = {
  NotStarted: "Not Started",
  InProgress: "In Progress",
  Completed: "Completed",
  NotInvited: "Not Invited",
};

export const DECISION_ON_CANDIDATE = {
  NotDecided: "Not Decided",
  Hired: "Hired",
  Rejected: "Rejected",
  InProcess: "In process",
};

export const BREAKPOINTS = {
  PHONE_SMALL: 320,
  PHONE: 480,
  TABLETSMALL: 550,
  TABLET: 768,
  LAPTOP: 1024,
  DESKTOP: 1200,
  LARGE_DESKTOP: 1440,
};

export const MEDIA_QUERIES = {
  PHONE_SMALL: `(max-width: ${BREAKPOINTS.PHONE_SMALL}px)`,
  PHONE: `(max-width: ${BREAKPOINTS.PHONE}px)`,
  TABLET_SMALL: `(max-width: ${BREAKPOINTS.TABLETSMALL}px)`,
  TABLET: `(max-width: ${BREAKPOINTS.TABLET}px)`,
  LAPTOP: `(max-width: ${BREAKPOINTS.LAPTOP}px)`,
  DESKTOP: `(max-width: ${BREAKPOINTS.DESKTOP}px)`,
  LARGE_DESKTOP: `(max-width: ${BREAKPOINTS.LARGE_DESKTOP}px)`,
};

export const programmingLanguagesLibrariesFrameworks = [
  // Programming Languages
  "JavaScript",
  "Python",
  "Java",
  "C#",
  "C++",
  "C",
  "Ruby",
  "Go",
  "Rust",
  "PHP",
  "Swift",
  "Kotlin",
  "TypeScript",
  "R",
  "Perl",
  "Dart",
  "Haskell",
  "Scala",
  "Objective-C",
  "Lua",
  "Elixir",
  "F#",
  "MATLAB",
  "Fortran",
  "Cobol",
  "SQL",
  "Shell Scripting",
  "Assembly",
  "Groovy",
  "VBA",

  // Web Frameworks
  "React",
  "Angular",
  "Vue.js",
  "Next.js",
  "Nuxt.js",
  "Svelte",
  "Express",
  "NestJS",
  "Django",
  "Flask",
  "Ruby on Rails",
  "Spring Boot",
  "ASP.NET Core",
  "Meteor",
  "Ember.js",
  "Backbone.js",
  "Gatsby",
  "Hugo",
  "Jekyll",
  "Laravel",
  "Symfony",
  "CodeIgniter",
  "CakePHP",
  "Phoenix",
  "FastAPI",

  // Mobile Development Frameworks
  "React Native",
  "Flutter",
  "Ionic",
  "Xamarin",
  "SwiftUI",
  "Kivy",
  "Apache Cordova",

  // Backend Libraries and Frameworks
  "Node.js",
  "Koa",
  "Hapi.js",
  "Fastify",
  "GraphQL",
  "Apollo Server",
  "Socket.io",
  "Mongoose",
  "Sequelize",
  "Prisma",
  "TypeORM",
  "Dapper",
  "Entity Framework",

  // Frontend Libraries
  "jQuery",
  "Lodash",
  "Moment.js",
  "Chart.js",
  "D3.js",
  "Three.js",
  "Redux",
  "MobX",
  "RxJS",
  "Recoil",

  // CSS Frameworks and Libraries
  "Bootstrap",
  "Tailwind CSS",
  "Material-UI",
  "Bulma",
  "Foundation",
  "Ant Design",
  "Chakra UI",
  "Semantic UI",

  // Game Development
  "Unity",
  "Unreal Engine",
  "Godot",
  "Pygame",
  "Cocos2d",
  "Phaser",
  "Construct",
  "CryEngine",

  // Machine Learning and Data Science
  "TensorFlow",
  "Keras",
  "PyTorch",
  "scikit-learn",
  "Pandas",
  "NumPy",
  "SciPy",
  "Matplotlib",
  "Seaborn",
  "OpenCV",
  "NLTK",
  "spaCy",
  "Hugging Face Transformers",
  "XGBoost",
  "LightGBM",
  "CatBoost",

  // DevOps and Infrastructure
  "Docker",
  "Kubernetes",
  "Terraform",
  "Ansible",
  "Puppet",
  "Chef",
  "Jenkins",
  "Travis CI",
  "CircleCI",
  "GitLab CI/CD",
  "Bamboo",

  // Testing Frameworks
  "Jest",
  "Mocha",
  "Chai",
  "Cypress",
  "Selenium",
  "Playwright",
  "Puppeteer",
  "JUnit",
  "RSpec",
  "TestNG",
  "xUnit",
  "NUnit",

  // Other Notable Technologies
  "Electron",
  "Tauri",
  "Apache Spark",
  "Hadoop",
  "Kafka",
  "RabbitMQ",
  "ActiveMQ",
  "Redis",
  "PostgreSQL",
  "MySQL",
  "MongoDB",
  "SQLite",
  "Firebase",
  "Supabase",
  "ElasticSearch",
  "Solr",
  "Apollo Client",
  "OpenAPI",
  "Swagger",
  "gRPC",
];

export const CANDIDATE_DETAILS_SECTION_TITLE = {
  SELF_INTRODUCTION: "Candidate Self-Introduction",
  HR_QUESTIONS: "Unscored Questions (General HR questions)",
  KNOWLEDGE_QUESTIONS: "Knowledge Questions",
  BONUS_KNOWLEDGE_QUESTIONS: "Bonus Knowledge Questions",
  SPECIFIC_QUESTIONS: "Specific Questions",
  BONUS_SPECIFIC_QUESTIONS: "Bonus Specific Questions",
  CODING_ASSIGNMENT: "Coding Assignment",
  CV_KNOWLEDGE_QUESTIONS: "CV Knowledge Questions",
};

export const USER_ROLE = {
  OWNER: "Owner",
  ADMIN: "Admin",
  EMPLOYEE: "Employee",
  VIEWER: "Viewer",
};
