import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomPacmanLoader } from "../../commonComponents/PacmanLoader";
import CandidateDialog from "./CandidateDialog";
import NotesComponent from "../../commonComponents/CandidateNotes";
import DecisionSelector from "./candidateCommon/DecisionSelector";
import DetailsButton from "./candidateCommon/DetailsButton";
import CandidatesCards from "./candidatesCards";

const CandidatesList = ({ candidates, columnsData, loading, isTableView }) => {
  const [candidatesList, setCandidatesList] = useState([...candidates]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (JSON.stringify(candidatesList) !== JSON.stringify(candidates)) {
      setCandidatesList([...candidates]);
    }
  }, [candidates]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    navigate(location.pathname);
    setSelectedCandidate(null);
  };

  useEffect(() => {
    if (urlParams?.get("dialog") !== "open") {
      setDialogOpen(false);
    }
  }, [location.search]);

  const updateDecision = (candidateId, newDecision) => {
    setCandidatesList((prevCandidates) =>
      prevCandidates.map((candidate) =>
        candidate.id === candidateId
          ? { ...candidate, decision: newDecision }
          : candidate
      )
    );
  };

  const columns = useMemo(() => {
    const renderNotesCell = (row) => {
      const handleNotesClick = () => {
        setIsNotesOpen(true);
        setSelectedCandidate({
          name: row.original.fullName,
          id: row.original.id,
        });
      };

      return (
        <button
          onClick={handleNotesClick}
          className="candidatesRowBtn"
          aria-label="View Candidate Notes"
          title="View Notes"
        >
          Notes
        </button>
      );
    };

    const renderDecisionCell = (row) => {
      return (
        <DecisionSelector
          candidateId={row.original.id}
          updateDecision={updateDecision}
          currentValue={row.original.decision}
        />
      );
    };

    const renderDetailsCell = (row) => {
      return (
        <DetailsButton
          candidateId={row.original.id}
          setDetailsLoading={setDetailsLoading}
          setSelectedCandidate={setSelectedCandidate}
          setDialogOpen={setDialogOpen}
          isDialogOpen={dialogOpen}
        />
      );
    };

    return columnsData.map((col) => {
      if (col.accessorKey === "notes") {
        return {
          ...col,
          Cell: ({ row }) => renderNotesCell(row),
        };
      }

      if (col.accessorKey === "decision") {
        return {
          ...col,
          Cell: ({ row }) => renderDecisionCell(row),
        };
      }

      if (col.accessorKey === "details") {
        return {
          ...col,
          Cell: ({ row }) => renderDetailsCell(row),
        };
      }

      return col;
    });
  }, [columnsData]);

  const table = useMaterialReactTable({
    className: "candidatesTable",
    columns: columns,
    data: candidatesList,
    enableColumnFilters: true,
    enablePagination: true,
    enableSorting: true,
    enableColumnOrdering: false,
    enableStickyHeader: true,
    enableRowSelection: false,
    enableColumnPinning: true,
    enableColumnActions: false,
    muiTableContainerProps: { sx: { maxHeight: "900px" } },
    initialState: {
      density: "compact",
    },
    muiTableBodyProps: {
      sx: {
        "& .MuiTableRow-root": {
          cursor: "pointer",
        },
      },
    },
  });

  return (
    <>
      {(loading || detailsLoading) && <CustomPacmanLoader />}
      {!loading && !detailsLoading && isTableView && (
        <MaterialReactTable table={table} className="candidatesTable" />
      )}
      {!loading && !detailsLoading && !isTableView && (
        <CandidatesCards
          candidatesList={candidatesList}
          setDetailsLoading={setDetailsLoading}
          setDialogOpen={setDialogOpen}
          setSelectedCandidate={setSelectedCandidate}
          dialogOpen={dialogOpen}
          updateDecision={updateDecision}
          setIsNotesOpen={setIsNotesOpen}
        />
      )}
      {selectedCandidate && (
        <CandidateDialog
          dialogOpen={dialogOpen}
          handleCloseDialog={handleCloseDialog}
          selectedCandidate={selectedCandidate}
        />
      )}
      {
        <NotesComponent
          candidateId={selectedCandidate?.id}
          open={isNotesOpen}
          onClose={() => {
            setIsNotesOpen(false);
            setSelectedCandidate(null);
          }}
          candidateName={selectedCandidate?.name}
        />
      }
    </>
  );
};

export default CandidatesList;
