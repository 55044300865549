import React, { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "./screenshotsGallery.scss";

const ScreenshotGallery = ({ screenShots }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(-1);

  // Transform screenshots into react-grid-gallery format
  const images = screenShots.map((url) => ({
    src: url,
    original: url,
    width: 140,
    height: 100,
  }));

  // Prepare slides for Lightbox
  const slides = images.map((img) => ({
    src: img.src,
  }));

  // Handle image click to open Lightbox
  const onImageClick = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <div
      className="screenshotGallery"
      //   style={{ maxHeight: "180px", overflow: "auto" }}
    >
      {/* Compact Image Grid */}
      <Gallery
        images={images}
        enableImageSelection={false}
        onClick={onImageClick}
      />

      {/* Full-screen Lightbox */}
      {currentImageIndex >= 0 && (
        <Lightbox
          slides={slides}
          open={currentImageIndex >= 0}
          index={currentImageIndex}
          close={() => setCurrentImageIndex(-1)}
        />
      )}
    </div>
  );
};

export default ScreenshotGallery;
