import React from "react";
import { PacmanLoader } from "react-spinners";

export const CustomPacmanLoader = () => {
  return (
    <div className="loaderWrapper">
      <PacmanLoader color="#4C2FBF" />
    </div>
  );
};
