import React, { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import {
  CANDIDATE_STATUS,
  DECISION_ON_CANDIDATE,
} from "../../../../Utils/constants";
import CandidateDialog from "../CandidateDialog";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const DetailsButton = ({
  candidateId,
  setDetailsLoading,
  setDialogOpen,
  setSelectedCandidate,
  isDialogOpen,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [fetchedCandidates, setFetchedCandidates] = useState({});
  const { companyId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const candidateId = urlParams?.get("candidateId");
    if (
      urlParams?.get("dialog") === "open" &&
      urlParams?.get("candidateId") &&
      !isDialogOpen
    ) {
      handleDetailsClick(candidateId);
    }
  }, [location.search]);

  const handleDetailsClick = async (candidateId) => {
    if (fetchedCandidates[candidateId]) {
      setSelectedCandidate(fetchedCandidates[candidateId]);
      setDialogOpen(true);
      navigate(`?dialog=open&candidateId=${candidateId}`);
      return;
    }

    try {
      setDetailsLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${companyId}/${candidateId}/getcandidate`,
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );

      if (response.status === 200) {
        const candidateData = {
          ...response.data,
          candidateStatus: CANDIDATE_STATUS[response.data.candidateStatus],
          decision: DECISION_ON_CANDIDATE[response.data.decision],
        };
        setFetchedCandidates((prev) => ({
          ...prev,
          [candidateId]: candidateData,
        }));
        setSelectedCandidate(candidateData);
        setDialogOpen(true);
        navigate(`?dialog=open&candidateId=${candidateId}`);
      }
    } catch (error) {
      console.error("Failed to fetch candidate details:", error);
      toast.error("Failed to fetch candidate details. Please try again.");
    } finally {
      setDetailsLoading(false);
    }
  };

  return (
    <div
      onClick={() => {
        handleDetailsClick(candidateId);
      }}
      className="candidatesRowBtn"
      aria-label="Open Details"
      title="Click to open details"
    >
      <Tooltip title="Click to open details">
        <>Details</>
      </Tooltip>
    </div>
  );
};

export default DetailsButton;
