import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Logout from "@mui/icons-material/Logout";
import AdbIcon from "@mui/icons-material/Adb";
import "./styles.scss";
import { useAuth0, User } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { CustomPacmanLoader } from "./PacmanLoader";
import StripeWrapper from "../company/payments/StripeWrapper";
import UserProfileDialog from "./UserProfile";
import ourLogo from "../../assets/imgs/i-need-a-logo-for-auto-interviewing-system--a-robo (9).svg";

const settings = ["Profile", "Payments", "Help"];

const TopHeaderBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [isUserProfileDialogOpen, setIsUserProfileDialogOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const { user, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      setLoading(true); // Auth0 is still loading
    } else if (
      !user &&
      !isAuthenticated &&
      (!localStorage.getItem("auth0Token") ||
        !sessionStorage.getItem("auth0Token"))
    ) {
      setLoading(false); // Auth0 finished loading, and user is not authenticated
      navigate("/", { replace: true });
    } else if (user && isAuthenticated) {
      setLoading(false); // Auth0 finished loading, and user is authenticated
    }
  }, [user, isAuthenticated, isLoading]);

  const handleLogout = async () => {
    try {
      // Clear only relevant storage keys
      localStorage.removeItem("auth0Token");
      sessionStorage.removeItem("auth0Token");

      // Redirect and logout via Auth0
      await logout({ returnTo: window.location.origin });
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (setting) => {
    switch (setting) {
      case "Profile":
        setIsUserProfileDialogOpen(true);
        break;
      case "Payments":
        setIsPaymentDialogOpen(true);
        break;
      case "Help":
        navigate("/help");
        break;
      default:
        break;
    }
    handleCloseUserMenu();
  };

  return (
    <>
      {loading && (
        <div className="mainLoaderWrapper">
          <CustomPacmanLoader />
        </div>
      )}
      {!loading && user && (
        <AppBar position="static" className="appBarWrapper">
          <Container maxWidth={false}>
            <Toolbar disableGutters>
              <div className="logoBigScreen">
                <img src={ourLogo} style={{ height: "50px", width: "50px" }} />
              </div>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{ display: { xs: "block", md: "none" } }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography
                      sx={{ textAlign: "center" }}
                      onClick={() => navigate("/create-company")}
                    >
                      Companies
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>

              <div className="logoSmallScreen">
                <img src={ourLogo} style={{ width: "50px", height: "50px" }} />
              </div>

              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <Button
                  onClick={() => {
                    navigate("/create-company");
                    handleCloseNavMenu();
                  }}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Companies
                </Button>
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user.name} src={user.picture} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => handleMenuItemClick(setting)}
                    >
                      <Typography sx={{ textAlign: "center" }}>
                        {setting}
                      </Typography>
                    </MenuItem>
                  ))}
                  <MenuItem
                    onClick={handleCloseUserMenu}
                    style={{ display: "flex" }}
                  >
                    <Typography
                      sx={{ textAlign: "center" }}
                      onClick={handleLogout}
                    >
                      <span>Logout</span>
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      )}
      <StripeWrapper
        open={isPaymentDialogOpen}
        onClose={() => setIsPaymentDialogOpen(false)}
      />
      <UserProfileDialog
        open={isUserProfileDialogOpen}
        onClose={() => setIsUserProfileDialogOpen(false)}
      />
    </>
  );
};

export default TopHeaderBar;
