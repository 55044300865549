import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./accordionDetails.scss";
import ReactPlayer from "react-player";
import { isNotNull } from "../../../Utils/Utils";
import GradingIcon from "@mui/icons-material/Grading";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";
import ScreenshotsGallery from "./ScreenshotsGallery.jsx";

const AccordionDetailsCandidate = ({ title, data, video, screenshots }) => {
  return (
    <Accordion className="accordionWrapper">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography className="accordionTitle">
          {title}
          {data?.conversations[0]?.grade?.score &&
            ` ${data.conversations[0]?.grade?.score}/100`}
        </Typography>
      </AccordionSummary>
      {data && (
        <AccordionDetails>
          <div className="summaryInfo">
            <span>Max time:</span> {data.maxTime}m:00s
          </div>
          <div className="summaryInfo">
            <span>Time spent:</span> {data.timeSpent}
          </div>
          <div className="summaryInfo">
            <span>Completed:</span>{" "}
            {data.conversations[0]?.isCompleted ? "Yes" : "No"}
          </div>
          {data.conversations[0]?.grade?.score && (
            <div className="summaryInfo">
              <span>Score: </span>
              {data.conversations[0]?.grade?.score}/100
            </div>
          )}
          {data.conversations[0]?.grade?.summary && (
            <div className="summaryInfo">
              <span>Feedback:</span> {data.conversations[0]?.grade?.summary}
            </div>
          )}
          {isNotNull(video) && (
            <div className="conversationVideo">
              <VideoPlayer
                options={{
                  autoplay: false,
                  controls: true,
                  responsive: true,
                  //   fluid: true,
                  sources: [
                    {
                      src: video,
                      type: "application/x-mpegURL",
                    },
                  ],
                }}
              />
            </div>
          )}
          {isNotNull(screenshots) && screenshots.length > 0 && (
            <div className="conversationScreenshots">
              <ScreenshotsGallery screenShots={screenshots} />
            </div>
          )}
          {data.conversations.length > 0 ? (
            <>
              {data.conversations[0].chat?.length > 0 && (
                <div className="conversationsTopics">
                  Conversation by topic:
                </div>
              )}
              {data.conversations.map((conversation, conversationIndex) => {
                if (conversation.chat && conversation.chat?.length > 0) {
                  return (
                    <Accordion key={conversationIndex} className="conversation">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${conversationIndex}-content`}
                        id={`panel${conversationIndex}-header`}
                        sx={{
                          maxHeight: "40px", // Restrict height
                          "&.Mui-expanded": {
                            maxHeight: "none", // Remove height restriction when expanded
                          },
                        }}
                      >
                        <span className="conversationTopicTitle">
                          {conversation.topic || "Untitled Conversation"}
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="topicScore">
                          {isNotNull(conversation.grade?.score) && (
                            <div className="summaryInfo">
                              <span>Score: </span>
                              {conversation.grade?.score}/100
                            </div>
                          )}
                          {isNotNull(conversation.grade?.summary) && (
                            <div className="summaryInfo">
                              <span>Topic summary: </span>
                              {conversation.grade?.summary}
                            </div>
                          )}
                        </div>
                        <div className="conversationText">
                          {conversation.chat.map((chat, chatIndex) => (
                            <div key={chatIndex}>
                              <div className="candidateQuestion">
                                <span className="QATitle">Question:</span>
                                <span className="QAText">
                                  {chat?.question || "No question provided"}
                                </span>
                              </div>
                              <div className="candidateAnswer">
                                <span className="QATitle">Answer:</span>
                                {chat?.answer ? (
                                  <span
                                    className="QAText"
                                    dangerouslySetInnerHTML={{
                                      __html: chat.answer
                                        .replaceAll("\n", "<br />")
                                        .replaceAll(
                                          "    ",
                                          "&nbsp;&nbsp;&nbsp;&nbsp;"
                                        )
                                        .replaceAll(
                                          "\t",
                                          "&nbsp;&nbsp;&nbsp;&nbsp;"
                                        ),
                                    }}
                                  />
                                ) : (
                                  <span className="QAText">
                                    No answer provided
                                  </span>
                                )}
                                {conversation.grade &&
                                  conversation.grade.comments &&
                                  conversation.grade.comments[chatIndex]
                                    ?.comment && (
                                    <div className="answerFeedback">
                                      <span className="feedbackTitle">
                                        Feedback <GradingIcon />
                                      </span>
                                      <span className="feedbackText">
                                        {
                                          conversation.grade.comments[chatIndex]
                                            ?.comment
                                        }
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                } else {
                  return null; // Skip conversations with no chat
                }
              })}
            </>
          ) : (
            <Typography className="summaryInfo" variant="body1">
              No conversation found
            </Typography>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default AccordionDetailsCandidate;
