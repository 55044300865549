import React from "react";
import PositionForm from "./AddPositionForm.jsx";

const AddPosition = () => {
  return (
    <div style={{ height: "100%" }}>
      <PositionForm />
    </div>
  );
};

export default AddPosition;
