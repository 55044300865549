import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Tooltip,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-toastify";

const UserProfileDialog = ({ open, onClose }) => {
  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  const { fullname, email } = userData;
  const [name, setName] = useState(fullname || "");
  const { getAccessTokenSilently } = useAuth0();

  const handlePasswordReset = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/User/resetpassword`,
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );
      if (response.status === 200) {
        toast.success("Password reset email sent successfully");
        onClose();
      }
    } catch (error) {
      console.error("Error triggering password reset:", error);
      toast.error("Failed to send password reset email");
      onClose();
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/User/updateuser`,
        { fullname: name },
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );
      if (response.data && response.status === 200) {
        const data = {
          ...userData,
          fullname: name,
        };
        localStorage.setItem("userData", JSON.stringify(data));
        toast.success("Profile updated successfully");
        onClose();
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      className="userProfileDialog"
    >
      <DialogTitle>User Profile</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit}>
          <div className="textFieldWrapper">
            <TextField
              size="small"
              label="Name"
              fullWidth
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="textFieldWrapper">
            <TextField
              size="small"
              label="Email"
              fullWidth
              variant="outlined"
              value={email || ""}
              disabled
            />
          </div>

          <div className="resetPasswordButton">
            <Tooltip title="Send password reset email">
              <Button onClick={handlePasswordReset}>Reset Password</Button>
            </Tooltip>
          </div>
        </form>
      </DialogContent>

      <DialogActions className="dialogActions">
        <Button onClick={handleSubmit} type="submit">
          Save
        </Button>
        <Button
          onClick={() => {
            setName(fullname || "");
            onClose();
          }}
          color="error"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserProfileDialog;
