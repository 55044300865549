import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/userSlice.js";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const {
    loginWithRedirect,
    isAuthenticated,
    user,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();
  const dispatch = useDispatch();
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ screen_hint: "signup" });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    const getToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
        localStorage.setItem("auth0Token", accessToken);
        sessionStorage.setItem("auth0Token", accessToken);
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    if (isAuthenticated) {
      getToken();
      dispatch(setUser(user));
    }
  }, [isAuthenticated, getAccessTokenSilently, dispatch, user]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/Auth/login`,
          {
            email: user.email,
            fullname: user.name,
            token: token,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          console.log("User logged in successfully");
          localStorage.setItem(
            "userData",
            JSON.stringify({
              companies: response.data.companies,
              email: user.email,
              fullname: response.data.fullName,
            })
          );
          navigate("/create-company");
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    if (token) {
      getUser();
    }
  }, [token, user, navigate]);

  return null;
};

export default SignUp;
