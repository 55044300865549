import React from "react";
import TopHeaderBar from "../commonComponents/topHeaderBar.jsx";
import { Outlet } from "react-router-dom";
import SideBar from "../commonComponents/SideBar.jsx";

const ManageUserCompany = () => {
  return (
    <div className="manageCompanyWrapper">
      <div className="companyHeaderWrapper">
        <TopHeaderBar />
      </div>
      <div className="manageCompanyContent">
        <SideBar />
        <div className="tabContent">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ManageUserCompany;
