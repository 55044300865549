import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
} from "@mui/material";
import "./payment.scss";
import { AppContext } from "../../componentsWrapper";
import { isNotNull } from "../../../Utils/Utils";
import { CustomPacmanLoader } from "../../commonComponents/PacmanLoader";

function AddPaymentMethodForm({ open, onClose }) {
  const context = useContext(AppContext);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [existingCardInfo, setExistingCardInfo] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!open) return;
    checkPaymentDetails();
  }, [open]);

  const checkPaymentDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/payment/details`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }
      );

      if (
        isNotNull(response.data) &&
        response.status === 200 &&
        response.data.last4Digits
      ) {
        setLoading(false);
        setExistingCardInfo(response.data);
        setSelectedTab(0);
      } else {
        setSelectedTab(1);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch payment details.");
      setLoading(false);
      onClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setError(null);

    try {
      const { paymentMethod, error: methodError } =
        await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        });

      if (methodError) {
        setError(methodError.message);
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/payment/attach`,
        { paymentMethodId: paymentMethod.id },
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }
      );

      if (response.status === 200) {
        checkPaymentDetails();
        toast.success("Payment method added successfully!");
      } else {
        toast.error("Failed to add payment method.");
        throw new Error("Failed to attach payment method.");
      }
    } catch (err) {
      toast.error("Failed to add payment method.");
      console.error(err);
      setError(err.response?.data?.message || "An unexpected error occurred.");
    } finally {
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      className="addPaymentWrapper"
    >
      <DialogTitle>Manage Payment Method</DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CustomPacmanLoader />
          </Box>
        ) : (
          <>
            <Tabs value={selectedTab} onChange={handleTabChange} fullWidth>
              <Tab
                label="Payment Details"
                disabled={!isNotNull(existingCardInfo)}
              />
              <Tab label="Payment Method" />
            </Tabs>

            <Box sx={{ padding: 3 }} className="paymentFormWrapper">
              {selectedTab === 0 && existingCardInfo && (
                <>
                  {existingCardInfo ? (
                    <div className="existingCardInfo">
                      {existingCardInfo.currentBill && (
                        <div className="billInfo">
                          <div className="billInfoTitle">
                            Current Bill Information
                          </div>
                          <div className="billPeriod">
                            Start:{" "}
                            <span className="billNums">
                              {new Date(
                                existingCardInfo.currentBill.usageRecords[0]?.periodStart
                              ).toLocaleDateString("en-CA")}{" "}
                            </span>
                            End:{" "}
                            <span className="billNums">
                              {new Date(
                                existingCardInfo.currentBill.usageRecords[0]?.periodEnd
                              ).toLocaleDateString("en-CA")}
                            </span>
                          </div>

                          <div className="billUsageRecords">
                            {existingCardInfo.currentBill.usageRecords.map(
                              (record) => (
                                <>
                                  <div className="record billInfoSection">
                                    {record.description} - Total:{" "}
                                    <span className="billNums">
                                      {record.amount}
                                    </span>{" "}
                                    $
                                  </div>
                                </>
                              )
                            )}
                          </div>
                          <div className="billTotal billInfoSection">
                            Amount Due:{" "}
                            <span className="billNums">
                              {existingCardInfo.currentBill.amountDue}
                            </span>{" "}
                            $
                          </div>
                        </div>
                      )}

                      <div className="payment-card">
                        <div className="chip"></div>
                        <div className="card-number">
                          **** **** **** {existingCardInfo.last4Digits}
                        </div>
                        <div className="card-details">
                          <div className="cardholder-name">John Doe</div>
                          <div className="expiry-date">
                            {existingCardInfo.expiration}
                          </div>
                        </div>
                        <div className="logo">{existingCardInfo.brand}</div>
                      </div>
                    </div>
                  ) : (
                    <Typography>No payment details available.</Typography>
                  )}
                </>
              )}
              {selectedTab === 1 && (
                <form onSubmit={handleSubmit} className="addPaymentForm">
                  <CardElement />
                  <Button
                    variant="text"
                    type="submit"
                    disabled={!stripe || loading}
                    className="paymentBtn"
                  >
                    {existingCardInfo ? "Change method" : "Save"}
                  </Button>
                  {error && <div style={{ color: "red" }}>{error}</div>}
                </form>
              )}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="error"
          variant="text"
          style={{ textTransform: "none" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddPaymentMethodForm;
