import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LabelImportantOutlinedIcon from "@mui/icons-material/LabelImportantOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import "./style.scss";

const EmailTemplate = ({ htmlToRender, open, onClose, emailSubject }) => {
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "gray",
            marginBottom: "20px",
          }}
        >
          <CloseIcon />
        </IconButton>
        {emailSubject && (
          <div className="emailSubjectPreview">
            <div className="subjectText">
              {emailSubject}
              <LabelImportantOutlinedIcon className="importantIcon" />
              <div className="inboxText">
                Inbox <ClearOutlinedIcon />
              </div>
            </div>
          </div>
        )}
      </DialogTitle>
      <DialogContent
        style={{ display: "flex", justifyContent: "center" }}
        dividers
      >
        <div
          dangerouslySetInnerHTML={{ __html: htmlToRender }}
          style={{
            width: "90%",
            height: "90%",
            overflow: "auto",
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EmailTemplate;
