import React, { useEffect, useState, useContext } from "react";
import { getUser } from "../../redux/userSlice.js";
import { useSelector } from "react-redux";
import TopHeaderBar from "../commonComponents/topHeaderBar.jsx";
import axios from "axios";
import { Button, CardHeader } from "@mui/material";
import "../company/employees/companies.scss";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Menu,
  MenuItem,
} from "@mui/material";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import apiClient from "../../Utils/axiosInterceptor.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppContext } from "../componentsWrapper.jsx";
import { isNotNull } from "../../Utils/Utils.js";
import NoCompaniesImg from "../../assets/imgs/noCompaniesWithText.png";

const CreateUserCompany = () => {
  const context = useContext(AppContext);
  const [companies, setcompanies] = useState([]);
  const [user, setUser] = useState(null);
  const [openCreateCompanyDialog, setOpenCreateCompanyDialog] = useState(false);
  const [anchorEls, setAnchorEls] = useState({});
  const { getAccessTokenSilently } = useAuth0();
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (isNotNull(context)) {
      setcompanies(context.companies ?? []);
    }

    if (context.user) {
      setUser(context.user);
    }
    const getcompanies = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/companies`,
          {
            headers: {
              Authorization: "Bearer " + (await getAccessTokenSilently()),
            },
          }
        );

        if (response.data.length === 0 || response.data === null) {
          setcompanies([]);
        } else {
          setcompanies(response.data);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    getcompanies();
  }, [context, context.companies]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      address: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Company name is required"),
      address: Yup.string(),
      description: Yup.string(),
    }),
    onSubmit: async (values) => {
      try {
        let response;
        if (isEditMode && selectedCompanyId) {
          response = await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/companies/${selectedCompanyId}/updateCompany`,
            {
              companyname: values.name,
              address: values.address,
              description: values.description,
            },
            {
              headers: {
                Authorization: "Bearer " + (await getAccessTokenSilently()),
              },
            }
          );
        } else {
          response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/companies`,
            {
              companyname: values.name,
              address: values.address,
              description: values.description,
            },
            {
              headers: {
                Authorization: "Bearer " + (await getAccessTokenSilently()),
              },
            }
          );
        }

        if (response.status === 200) {
          if (companies !== null && companies.length > 0 && !isEditMode) {
            setcompanies((prevcompanies) => [...prevcompanies, response.data]);
          } else if (isEditMode) {
            let edited = companies?.map((company) => {
              if (company.id === response.data.id) {
                return (company = response.data);
              } else {
                return company;
              }
            });
            setcompanies(edited);
          } else {
            setcompanies([response.data]);
          }
          setOpenCreateCompanyDialog(false);
          setIsEditMode(false);
          formik.resetForm();
        }
      } catch (error) {
        console.error("Error creating company:", error);
      }
    },
  });

  const handleMenuOpen = (event, name) => {
    setAnchorEls((prev) => ({ ...prev, [name]: event.currentTarget }));
  };

  const handleMenuClose = (name) => {
    setAnchorEls((prev) => ({ ...prev, [name]: null }));
  };

  const handleEditCompany = (company) => {
    setIsEditMode(true);
    handleMenuClose(company.name);
    formik.setFieldValue("name", company.name);
    formik.setFieldValue("address", company.address);
    formik.setFieldValue("description", company.description);
    setOpenCreateCompanyDialog(true);
  };

  const handleDeleteCompany = (company) => {
    handleMenuClose(company.name);
  };

  return (
    <div className="createUserCompanyPage">
      <TopHeaderBar />
      <div className="companiesWrapper">
        <div className="createCompanyWrapper">
          {companies?.length < 1 && (
            <>
              <div className="companyText">
                No saved company found... In order to continue please add your
                company details
              </div>
              <img
                src={NoCompaniesImg}
                alt="No companies"
                className="noCompaniesImg"
              />
            </>
          )}
          <Button
            onClick={() => setOpenCreateCompanyDialog(true)}
            className="addCompanyBtn"
          >
            Add a company
          </Button>
        </div>
        {companies && companies?.length > 0 && (
          <div className="companiesListContainer">
            {companies?.map((company) => (
              <Card
                className="companyCard"
                key={company.name}
                sx={{
                  minWidth: 275,
                  maxWidth: 275,
                  width: 275,
                  height: "fit-content",
                  minHeight: 220,
                  maxHeight: 220,
                }}
                onClick={() => {
                  context.selectedCompany = company;
                  navigate(`/manage-company/${company.id}/positionsList`);
                }}
              >
                <CardContent>
                  <CardHeader
                    className="companyCardHeader"
                    title={company.name}
                    action={
                      company.ownerEmail === user.email && (
                        <>
                          <IconButton
                            aria-label="settings"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleMenuOpen(event, company.name);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEls[company.name]}
                            open={Boolean(anchorEls[company.name])}
                            onClose={() => handleMenuClose(company.name)}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <MenuItem
                              onClick={(e) => {
                                handleEditCompany(company);
                                setSelectedCompanyId(company.id);
                              }}
                            >
                              Edit
                            </MenuItem>
                            {/* <MenuItem
                            onClick={(e) => handleDeleteCompany(company)}
                          >
                            Delete
                          </MenuItem> */}
                          </Menu>
                        </>
                      )
                    }
                  />
                  {company.address && (
                    <Typography
                      gutterBottom
                      sx={{ color: "text.secondary", fontSize: 14 }}
                      className="companyDesc"
                    >
                      {company.address}
                    </Typography>
                  )}
                  {company.description && (
                    <Typography variant="body2" className="companyDesc">
                      <div>{company.description}</div>
                    </Typography>
                  )}
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </div>

      <Dialog open={openCreateCompanyDialog} className="addCompanyDialog">
        <DialogTitle textAlign="center">
          {isEditMode ? "Edit company" : "Add a company"}
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <DialogContentText>
              {!isEditMode &&
                "Provide details of the company you would like to add"}
            </DialogContentText>
            <TextField
              required
              margin="dense"
              id="name"
              name="name"
              label="Company name"
              type="text"
              fullWidth
              variant="standard"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              margin="dense"
              id="address"
              name="address"
              label="Address"
              type="text"
              fullWidth
              variant="standard"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
            <TextField
              multiline
              margin="dense"
              id="description"
              name="description"
              label="Description"
              type="text"
              fullWidth
              variant="standard"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
          </DialogContent>
          <DialogActions className="addCompanyDialogBtns">
            <Button type="submit" className="createBtn">
              Save
            </Button>
            <Button
              onClick={() => {
                setOpenCreateCompanyDialog(false);
                setIsEditMode(false);
                formik.resetForm();
              }}
              className="cancelBtn"
              color="error"
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default CreateUserCompany;
