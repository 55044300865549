import React, { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { MEDIA_QUERIES, USER_ROLE } from "../../Utils/constants.js";
import Switch from "@mui/material/Switch";
import "./styles.scss";
import { isNotNull } from "../../Utils/Utils.js";

const SideBar = () => {
  const isMobile = useMediaQuery(MEDIA_QUERIES.PHONE);
  const [collapsed, setCollapsed] = useState(isMobile ? true : false);
  const { companyId } = useParams();
  const userData = localStorage.getItem("userData");
  const userRole = JSON.parse(userData)?.companies[companyId]?.role;
  // const isAdminTabAllowed =
  //   (isNotNull(userData) && userRole === USER_ROLE.ADMIN) ||
  //   userRole === USER_ROLE.OWNER;

  const navigate = useNavigate();
  const location = useLocation();

  // Toggle Sidebar Collapse
  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (isMobile) {
      setCollapsed(true);
    }
  };

  return (
    <Sidebar
      className="sidebar"
      collapsed={collapsed}
      width={isMobile ? "100%" : 250}
      backgroundColor="#f0f0f0"
      onBackdropClick={handleToggle}
      // onBreakPoint={isTablet}
    >
      <div
        style={{ padding: "10px", textAlign: "center", marginTop: "auto" }}
      ></div>
      <Menu>
        <MenuItem
          icon={<ListAltIcon />}
          active={location.pathname.includes("/positionsList")}
          onClick={() => handleNavigation("positionsList")}
        >
          Positions
        </MenuItem>
        <MenuItem
          icon={<PostAddIcon />}
          active={location.pathname.endsWith("/addPosition")}
          onClick={() => handleNavigation("addPosition")}
        >
          Add Position
        </MenuItem>
        <MenuItem
          icon={<BadgeOutlinedIcon />}
          active={location.pathname.endsWith("/employees")}
          onClick={() => handleNavigation("employees")}
        >
          Employees
        </MenuItem>

        {/* {isAdminTabAllowed && (
          <MenuItem
            icon={<CreditScoreIcon />}
            active={location.pathname.endsWith("/admin")}
            onClick={() => handleNavigation("admin")}
          >
            Admin
          </MenuItem>
        )} */}
      </Menu>
      <div className="collapseToggle">
        <div>
          <Switch
            id="collapse"
            checked={!collapsed}
            onChange={handleToggle}
            label="Collapse"
          />
        </div>
      </div>
    </Sidebar>
  );
};

export default SideBar;
