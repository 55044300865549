import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { EDIT_INVITATION_EXPLANATION } from "../../Utils/texts";

const InfoSection = ({ text, title }) => {
  return (
    <div className="infoSection">
      <div className="infoSectionTitle">
        <InfoOutlinedIcon />
        {title}
      </div>
      <div
        className="infoSectionExplanation"
        dangerouslySetInnerHTML={{ __html: EDIT_INVITATION_EXPLANATION }}
        style={{ flex: 1 }}
      />
    </div>
  );
};

export default InfoSection;
