import React from "react";
import { DECISION_ON_CANDIDATE } from "../../../../Utils/constants";
import { getKeyByValue } from "../../../../Utils/Utils";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

const DecisionSelector = ({ updateDecision, currentValue, candidateId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { companyId } = useParams();

  const handleDecisionChange = async (e) => {
    const newDecision = e.target.value;

    updateDecision(candidateId, newDecision);

    const decisionKey = getKeyByValue(DECISION_ON_CANDIDATE, newDecision);
    try {
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${companyId}/${candidateId}/updateCandidate`,
        { decision: decisionKey },
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );
    } catch (error) {
      console.error("Failed to update decision:", error);
    }
  };

  return (
    <select
      value={currentValue || DECISION_ON_CANDIDATE.NotDecided}
      onChange={handleDecisionChange}
      className="candidatesRowBtn decisionSelect"
      aria-label="Candidate Decision"
    >
      <option value={DECISION_ON_CANDIDATE.NotDecided}>
        {DECISION_ON_CANDIDATE.NotDecided}
      </option>
      <option value={DECISION_ON_CANDIDATE.InProcess}>
        {DECISION_ON_CANDIDATE.InProcess}
      </option>
      <option value={DECISION_ON_CANDIDATE.Hired}>
        {DECISION_ON_CANDIDATE.Hired}
      </option>
      <option value={DECISION_ON_CANDIDATE.Rejected}>
        {DECISION_ON_CANDIDATE.Rejected}
      </option>
    </select>
  );
};
export default DecisionSelector;
