import { Card, CardContent, Tooltip } from "@mui/material";
import React, { useRef } from "react";
import DecisionSelector from "./candidateCommon/DecisionSelector";
import DetailsButton from "./candidateCommon/DetailsButton";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import "./candidatesCards.scss";
import { MEDIA_QUERIES } from "../../../Utils/constants";
import { useMediaQuery } from "@mui/material";

const CandidatesCards = ({
  candidatesList,
  setDetailsLoading,
  setDialogOpen,
  setSelectedCandidate,
  dialogOpen,
  updateDecision,
  setIsNotesOpen,
}) => {
  const isTablet = useMediaQuery(MEDIA_QUERIES.TABLET_SMALL);
  const detailsButtonRef = useRef();
  return (
    <div className="candidatesCardsWrapper">
      {candidatesList?.map((candidate, index) => {
        const handleCardClick = (e) => {
          // Ignore clicks inside .cardActions
          if (!e.target.closest(".cardActions")) {
            detailsButtonRef.current?.click(); // Simulate DetailsButton click
          }
        };

        return (
          <Card key={index} className="candidateCard" onClick={handleCardClick}>
            {!isTablet && (
              <div className="candidateDetailsBtn">
                <DetailsButton
                  ref={detailsButtonRef} // Attach ref to DetailsButton
                  candidateId={candidate.id}
                  setDetailsLoading={setDetailsLoading}
                  setDialogOpen={setDialogOpen}
                  setSelectedCandidate={setSelectedCandidate}
                  isDialogOpen={dialogOpen}
                />
              </div>
            )}
            <CardContent>
              <div className="candidateDetails">
                <div className="candidateName cardText">
                  {candidate.fullName}
                </div>
                <div className="candidateEmail cardText cardDetailsText">
                  {candidate.userEmail}
                </div>
                <div className="candidateStatus cardText cardDetailsText">
                  <span>Status: </span>
                  {candidate.candidateStatus}
                </div>
                <div className="candidateScore cardText cardDetailsText">
                  <span>Score: </span>
                  {candidate.score || "N/A"}
                </div>
                <div className="cardActions">
                  <div className="candidateDecision">
                    <DecisionSelector
                      updateDecision={updateDecision}
                      currentValue={candidate.decision}
                      candidateId={candidate.id}
                    />
                  </div>

                  <Tooltip title="View/Add Notes" placement="top">
                    <AssignmentOutlinedIcon
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent card click logic
                        setIsNotesOpen(true);
                        setSelectedCandidate({
                          name: candidate.fullName,
                          id: candidate.id,
                        });
                      }}
                    />
                  </Tooltip>
                </div>
                {isTablet && (
                  <div className="candidateDetailsBtnTablet">
                    <DetailsButton
                      ref={detailsButtonRef} // Attach ref to DetailsButton
                      candidateId={candidate.id}
                      setDetailsLoading={setDetailsLoading}
                      setDialogOpen={setDialogOpen}
                      setSelectedCandidate={setSelectedCandidate}
                      isDialogOpen={dialogOpen}
                    />
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export default CandidatesCards;
