export const EDIT_INVITATION_EXPLANATION = `
  <p>In the text provided, you can use placeholders enclosed in double curly braces, such as:</p>
  <ul>
    <li><strong>{{PositionName}}</strong>: The name of the position, such as "Software Engineer".</li>
    <li><strong>{{CompanyName}}</strong>: The name of the company, such as "TechCorp".</li>
    <li><strong>{{ValidUntilDate}}</strong>: A specific date, e.g., "20.01.2025". The date will be calculated according to the invitation expiration days count.</li>
    <li><strong>{{CandidateName}}</strong>: The invited candidate's name, e.g., "Tom Cruise".</li>
  </ul>
  <p><strong>Example:</strong></p>
  <p>
    <strong>You type:</strong> "Hello {{CandidateName}}! We are excited to invite you to complete an online interview for the {{PositionName}} role at {{CompanyName}}. 
    This interview is valid until {{ValidUntilDate}}."
  </p>
  <p>
    <strong>Candidate sees:</strong> "Hello Tom Cruise! We are excited to invite you to complete an online interview for the Software Engineer role at TechCorp. 
    This interview is valid until 20.05.2025."
  </p>
`;
